const host = window.API.wrap.apiUrl;
const EXCLUSIVE_PREFIX_NEO = `${host}/_exclusive/neo`;

const neoEndpoints = {
  toggles: `${EXCLUSIVE_PREFIX_NEO}/toggles`,
  configs: `${EXCLUSIVE_PREFIX_NEO}/configs`,
};

const interfaces = {
  getNeoConfigs(data) {
    return this.post(`${neoEndpoints.configs}`, 'user', {data, enableRequestId: true});
  },
  getNeoToggles(data) {
    return this.post(`${neoEndpoints.toggles}`, 'user', {data, enableRequestId: true});
  },
};

export default window.API.wrap(interfaces, 'xhr-neo');
