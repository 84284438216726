<template>
  <div v-if="attachmentUrl && redirectUrl" class="edukarir-section">
    <a :href="redirectUrl" target="_blank">
      <img :src="attachmentUrl" alt="edukarir-banner" width="100%" />
    </a>
  </div>
</template>

<script lang="js">
import neoApi from 'shared/javascripts/api/neo';

export default {
  name: 'App',
  data() {
    return {
      attachmentUrl: null,
      redirectUrl: null,
    };
  },
  async mounted() {
    try {
      const { data } = await neoApi.getNeoConfigs({ id: ['homepage-edukarir-section'] });
      const configData = data?.[0]?.data;
      if (configData) {
        this.attachmentUrl = configData.attachment_url;
        this.redirectUrl = configData.redirect_url;
      }
    } catch (error) {
      console.error('Error fetching config:', error);
    }
  },
};
</script>
